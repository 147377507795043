import React, { useState, useRef } from 'react'
import Header from '../../components/generals/header'
import DataTableLazyApps from '../../components/generals/datatables/dataTableLazyApps'
import { Toast } from 'primereact/toast'
import store from '../../reducer/store'
import styles from '../../css/applications/Apps.module.css'
import stylesIndex from './../../css/Index.module.css'
import { putData } from '../../actions/servicesHttp'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { Dropdown } from 'primereact/dropdown'
import MostUsedApps from '../../components/generals/MostUsedApps'

export default function Applications () {
  const toast = useRef(null)
  const { user } = store.getState()
  const role = user.roles

  //* estados de componentes
  const [reload, setReload] = useState(false)
  const [category, setCategory] = useState(9)
  const [editCategories, setEditCategories] = useState(false)
  const [dateFilter, setDateFilter] = useState(0)
  const [historic, setHistoric] = useState(false)

  // Categorias
  const [categories] = useState([
    { name: 'No Permitidas', id: 0 },
    { name: 'Sistema', id: 1 },
    { name: 'Educativas', id: 2 },
    { name: 'No Educativas', id: 3 },
    { name: 'Productividad', id: 4 },
    { name: 'Internet', id: 5 },
    { name: 'Ocio', id: 6 },
    { name: 'Otras', id: 7 },
    { name: 'Sin Asignar', id: 8 },
    { name: 'Todas', id: 9 }
  ])

  // Filtro de fecha
  const dates = [
    { name: 'Día', value: 1 },
    { name: 'Semana', value: 7 },
    { name: 'Mes', value: 31 }
  ]

  // aplicaciones por actualizar
  const appsToPut = []

  const getApp = async (data) => {
    let appFound
    const app = data

    if (appsToPut.length === 0) {
      appsToPut.push(app)
    } else {
      appFound = appsToPut.find((item) => item.id === app.id)
      if (appFound === undefined) {
        appsToPut.push(app)
      } else {
        appsToPut.forEach((item) =>
          item.id === appFound.id ? (item.groupApp = app.groupApp) : null
        )
      }
    }
  }

  const getCategory = (value) => {
    switch (value) {
      case 'No Permitidas':
        return 0
      case 'Sistema':
        return 1
      case 'Educativas':
        return 2
      case 'No Educativas':
        return 3
      case 'Productividad':
        return 4
      case 'Internet':
        return 5
      case 'Ocio':
        return 6
      case 'Otras':
        return 7
      default:
        return null
    }
  }

  const showToast = (type, value, message) => {
    toast.current.show({
      severity: type,
      summary: value,
      detail: message,
      life: 3000
    })
  }

  const putApps = async (data) => {
    let response
    if (data.length === 0) {
      showToast('info', 'Aviso', 'No se realizaron cambios')
      return
    } else {
      data.forEach(element => {
        element.groupApp = element.groupApp === 'Eliminar Categoría' ? null : getCategory(element.groupApp)
      })

      response = await putData('applications', data)

      if (response.status === 200) {
        setReload(true)
        showToast('success', 'Éxito', 'Modificación exitosa')
      } else {
        showToast('error', 'Error', 'Modificación fallida')
      }
    }

    data = []
  }

  return (
    <div className={`content-wrapper  ${stylesIndex.flexContent}`}>
      <div className={`content  ${stylesIndex.mainContent}`}>
        <Header title="Aplicaciones" />
        <Toast
          ref={toast}
          className={styles.toast}
        />
        <div className={styles.filterContainer}>
          <div className={styles.filterByCategories}>
            <p className={styles.floatLabel}>Categorías</p>
            <Dropdown
              disabled={editCategories}
              value={category}
              options={categories}
              onChange={(e) => setCategory(e.value)}
              optionLabel="name"
              optionValue="id"
              placeholder="Seleccionar categoría"
              className={styles.dropdown}
            />
          </div>
          <div className={styles.btnsContainer}>
            <ButtonGroup className={styles.dateFilterContainer}>
              {dates.map((date, index) => (
                <ToggleButton
                  key={index}
                  id={`date-${index}`}
                  type="radio"
                  variant="secondary"
                  name="date"
                  disabled={editCategories}
                  value={date.value}
                  checked={date.value === dateFilter}
                  className={styles.btnDateUseTimeApps}
                  onChange={(e) => {
                    setDateFilter(parseInt(e.target.value))
                    setHistoric(false)
                  }}
                >
                  {date.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
            <ToggleButton
              key={'date-historic'}
              id={'date-historic'}
              type="radio"
              variant="secondary"
              name="date-historic"
              disabled={editCategories}
              value={historic}
              checked={historic}
              className={`${styles.btnDateHistoric} ${styles.tooltip}`}
              onChange={() => {
                setHistoric(!historic)
                setDateFilter(0)
              }}
            >
              <i className={`fa-solid fa-clock-rotate-left ${styles}`} />
              <span className={styles.tooltipText}>Ver historial</span>
            </ToggleButton>
          </div>
        </div>
        <MostUsedApps
          category={category}
          dateFilter={dateFilter}
          editCategories={editCategories}
        />
        <DataTableLazyApps
          editCategories={editCategories}
          setEditCategories={setEditCategories}
          category={category}
          putApps={putApps}
          appsToPut={appsToPut}
          getApp={getApp}
          getCategory={getCategory}
          reload={reload}
          setReload={setReload}
          categories={categories}
          dateFilter={dateFilter}
          editCategoryApplication={role?.applicationsPage.editCategoryApplication}
          exportsCsv={role?.applicationsPage.exportCsvApplications}
          exportsPdf={role?.applicationsPage.exportPdfApplications}
        />
      </div>
    </div>
  )
}

// suma o resta dias
export function sumarDias (fecha, dias) {
  const fechaReturn = fecha.setDate(fecha.getDate() + dias)
  return fechaReturn
}

// llevar segundos a hora, minutos y segundos en string
export function secondsToString (seconds) {
  let hour = Math.floor(seconds / 3600)
  hour = (hour < 10) ? '0' + hour : hour
  let minute = Math.floor((seconds / 60) % 60)
  minute = (minute < 10) ? '0' + minute : minute
  let second = seconds % 60
  second = (second < 10) ? '0' + second : second
  return hour + ':' + minute + ':' + second
}

// diferencia de días
export function dateDiff (day, days, operation) {
  const date = currentAndPreviousDate(days, operation)
  const previosDate = date[1]
  const today = date[0]

  if (day.getTime() >= previosDate.getTime()) {
    const t1 = day.getTime()
    const t2 = today.getTime()
    return (Math.floor((t2 - t1) / (1000 * 60 * 60 * 24)))
  }
}

// diferencia de tiempo entre dos fechas
export function differenceTime (d1, d2) {
  const t2 = d2.getTime()
  const t1 = d1.getTime()

  return (t2 > t1 ? Math.floor((t2 - t1) / 1000) : Math.floor((t1 - t2) / 1000))
}

// formatea un array con los dias de la semana
export function ArrayWeek (week, days) {
  const cantDays = week.length
  let daysNum = days.getDay() - 1
  return week.map((days, index) => {
    daysNum = daysNum + 1
    if (daysNum > cantDays - 1) {
      daysNum = 0
      return week[0]
    }
    return week[daysNum]
  })
}

export function formatDate (date) {
  if (date === null) {
    return date
  } else {
    const fecha = new Date(date)
    const formatdate = fecha.toLocaleString('es-AR', { hour12: false })
    return formatdate
  }
}

// Obtener en formato Date la fecha actual y una fecha anterior o posterior
export function currentAndPreviousDate (days, operation) {
  const today = new Date()
  let day
  if (operation === '-') {
    day = today.getDate() - days
  } else if (operation === '+') {
    day = today.getDate() + days
  }
  const month = today.getMonth()
  const year = today.getFullYear()
  const date = new Date(year, month, day, 0, 0, 0, 0)

  return [today, date]
}

// invertir el string de la fecha
export function reverseDateString (date) {
  const day = date.split('-')
  const response = day[2] + '-' + day[1] + '-' + day[0]
  return (response)
}

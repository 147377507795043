import React from 'react'
import { Badge } from 'primereact/badge'
import '../../css/generals/states.css'
import PropTypes from 'prop-types'

export default function States ({ title, alert, type }) {
  const styleBtn = { fontSize: 'x-large', color: title === 'Desbloqueado' ? '#08BE5B' : title === 'Bloqueado' ? '#d82c2c' : '#989898' }
  const styleBtnChrome = { fontSize: '1.8rem', color: title === 'Desbloqueado' ? '#08BE5B' : title === 'Bloqueado' ? '#d82c2c' : '#989898' }
  const iconChromebook = <span className="material-symbols-outlined" style={styleBtnChrome}>{type === 'chromebook' ? 'laptop_chromebook' : 'laptop_windows'}</span>

  const iconClass =
    type === 'computadora'
      ? 'pi pi-desktop'
      : type === 'tablet'
        ? 'pi pi-tablet'
        : 'pi pi-circle-fill'

  return (
    <>
      {alert && alert.checkAlert
        ? (
          <Badge
            value="!"
            severity="danger"
            className="alert"
          ></Badge>
          )
        : (
          <></>
          )}

      {type === 'chromebook' || type === 'notebook' ? (iconChromebook) : <i className={iconClass} style={styleBtn} />}
    </>
  )
}

States.propTypes = {
  title: PropTypes.string,
  alert: PropTypes.object,
  type: PropTypes.object
}

import React from 'react'
import ModalCommands from '../ModalCommand'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'

export default function ModalSendCommands ({ devices, onHide, type, component, show, onSelecteds, group, realTime, socketSendActions, socketReceiveActions }) {
  const closeModal = () => onHide()

  return (
    <>
      <Modal
        show={show}
        size="xl"
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="sendCommand"
        backdrop="static"
        keyboard={false}
      >
        <ModalCommands
          devices={devices}
          onHide={closeModal}
          type={type}
          component={component}
          onSelecteds={onSelecteds}
          group={group}
          socketSendActions={socketSendActions}
          socketReceiveActions={socketReceiveActions}
          realTime={realTime}
        />
      </Modal>
    </>
  )
}

ModalSendCommands.propTypes = {
  devices: PropTypes.array,
  onHide: PropTypes.func,
  type: PropTypes.string,
  component: PropTypes.string,
  title: PropTypes.string,
  show: PropTypes.bool,
  onSelecteds: PropTypes.func,
  group: PropTypes.number,
  realTime: PropTypes.bool,
  socketSendActions: PropTypes.func,
  socketReceiveActions: PropTypes.func
}

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getData } from '../../actions/servicesHttp'
import ModalGeneric from './modal'
import DataTableDemo from '../generals/datatables/datatable'
import PropTypes from 'prop-types'

export default function ModalInforms ({ show, onHide }) {
  // console.log(onHide, show)
  const { id } = useParams()
  // ? urls de la API
  const [lossReportsUrl] = useState('device/loss_reports')
  const [trackingUrl] = useState('allTrackings')

  //* estados de componentes
  const [reports, setReports] = useState({
    cases: null,
    ticket: null
  })
  const [modal, setModal] = useState(false)

  //* llamador de datos de API
  const getRequest = async (url, data) => {
    const response = await getData(url, data)
    // console.log(response, url)
    if (response.status === 200) {
      sanitizationData(response.data)
    } else {
      console.log({ error: true, code: response.code })
    }
  }

  useEffect(() => {
    if (show) getRequest(lossReportsUrl, id)
  }, [lossReportsUrl, id, show])

  const sanitizationData = (data) => {
    const formatData = data.map(info => {
      // console.log(info)
      const dateOrder = !info.start_date
        ? 'Sin fecha de comienzo'
        : new Date(info.start_date).toLocaleString('es-AR', { hour12: false })
      const dateFinish = !info.finish_date
        ? 'Sin fecha de finalización'
        : new Date(info.finish_date).toLocaleString('es-AR', { hour12: false })

      info.start_date = dateOrder
      info.finish_date = dateFinish
      return info
    })
    setReports({
      ...reports,
      cases: formatData
    })
  }

  const viewTicket = async (info) => {
    let array = []
    const trackingId = info?.trackingId
    const { data } = await getData(trackingUrl, trackingId)

    if (data && data.length > 0) {
      array = data[0].tracking
      array?.forEach((element) => {
        if (!element.place) element.place = 'No Disponible'
      })
    }

    setReports({
      ...reports,
      ticket: array
    })
    onHide()
    setModal(true)
  }

  return (
    <>
      {/* MODAL DE TICKET DE ROBO */}
      <ModalGeneric
        show={modal}
        onHide={() => setModal(false)}
        size="xl"
        id="tracking"
        onClose={() => setModal(false)}
        title="Tracking de extravio"
        btnError="Cerrar"
        btnSuccess="Agregar"
        viewBtnSuccess={false}
      >
        <DataTableDemo
          data={reports.ticket}
          table="tickets"
          rowSelecteds={false}
          rowStates={false}
          exportCsv={true}
          exportPdf={true}
          actionDashboard={false}
          actionDelete={false}
          searchs={['order_id']}
          editUsers={false}
          columns={[
            {
              field: 'place',
              header: 'Dirección'
            },
            {
              field: 'lat',
              header: 'Latitud'
            },
            {
              field: 'lon',
              header: 'Longitud'
            },
            {
              field: 'acc',
              header: 'Precisión'
            },
            {
              field: 'ip_lan',
              header: 'IP LAN'
            },
            {
              field: 'ip_wan',
              header: 'IP WAN'
            }
          ]}
        />
      </ModalGeneric>

      {/* MODAL DE HISTORIAL DE ROBOS */}
      <ModalGeneric
        show={show}
        onHide={onHide}
        size="xl"
        id="cases"
        onClose={onHide}
        title="Casos de Extravio"
        btnError="Cerrar"
        btnSuccess="Agregar"
        viewBtnSuccess={false}
      >
        <DataTableDemo
          data={reports.cases}
          table="cases"
          rowSelecteds={false}
          rowStates={false}
          exportCsv={true}
          exportPdf={true}
          actionDashboard={false}
          actionDelete={false}
          viewBtnAction={true}
          link={false}
          titleBtn="Ver Tracking"
          actionBtn={viewTicket}
          searchs={['order_id']}
          editUsers={false}
          columns={[
            {
              field: 'order_id',
              header: 'Número de Extravio'
            },
            {
              field: 'start_date',
              header: 'Fecha de comienzo'
            },
            {
              field: 'finish_date',
              header: 'Fecha de finalización'
            },
            {
              field: 'user.user',
              header: 'Usuario'
            }
          ]}
        />
      </ModalGeneric>
    </>
  )
}

ModalInforms.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool
}

import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { getData } from '../../../actions/servicesHttp'
import Loading from '../loading'
import { formatDate, currentAndPreviousDate } from '../charts/utils/DatesFormats'
import { Dropdown } from 'primereact/dropdown'
import States from '../states'
import ActionDelete from './actionDelete'
import store from '../../../reducer/store'
import ActionDashboard from './actionDashboard'
import NavLinkDashboardDevices from './NavLinkDashboardDevices'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

import '../../../css/generals/dataTable.css'
import { exportPdf, exportCSV } from './utilsDataTableLazy'
import PropTypes from 'prop-types'

export default function DataTableLazyDevices ({ table, selectedRows, dateQuery, onSelecteds, groupId, exportsPdf, exportsCsv, rowSelecteds, clearSelection, actionDashboard, columnsRole, actionDelete }) {
  const { user } = store.getState()
  const dt = useRef(null)
  const it = useRef(null)
  const [loading, setLoading] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [devices, setDevices] = useState(null)
  console.log(devices)
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(10)
  const [selectedFilter, setSelectedFilter] = useState('identity')
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filter: 'identity',
    reload: false
  })
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const columns = columnsRole.filter((column) => column.field !== 'type' && column.field !== 'status_lock' && column.field !== 'id_user')
  const columnIdUser = columnsRole.filter((column) => column.field === 'id_user')

  const loadLazyData = async (props) => {
    let response
    setLoading(true)
    if (props) {
      setLazyParams({
        ...lazyParams,
        reload: props
      })
    }

    const sortField = lazyParams.sortField
    const sortOrder = lazyParams.sortOrder

    const json = {
      like:
        globalFilterValue === '' || it.current.value === ''
          ? null
          : lazyParams.filter === 'identity' || lazyParams.filter === undefined
            ? globalFilterValue.toUpperCase()
            : globalFilterValue,
      offset: lazyParams.first,
      limit: lazyParams.rows,
      order:
        sortField === null || sortOrder === null
          ? ['last_date', 'DESC']
          : sortOrder === 1
            ? [sortField, 'ASC']
            : [sortField, 'DESC'],
      column: lazyParams.filter === undefined ? 'identity' : lazyParams.filter
    }
    if (table === 'reports') {
      const date = currentAndPreviousDate(6, '-')
      const day = new Date(date[1]).toISOString().split('T')[0]
      json.include = day + ' 00:00:00.000 +00:00'
      response = await getData('reports', JSON.stringify(json))
    } else {
      response = await getData('devices', JSON.stringify(json))
    }

    if (response?.data.error && response?.data.error === true) {
      setDevices([])
      setLoading(false)
    } else {
      const data = response?.data.rows

      if (groupId) {
        data.forEach((device) => {
          const answer = device.groups.findIndex(
            (group) => group.id === parseInt(groupId)
          )
          if (answer === -1) {
            device.is_selectable = true
          } else {
            device.is_selectable = false
          }
        })
      }

      data?.forEach((device) => {
        if (groupId) {
          if (device.licence === null) device.is_selectable = false
        }
      })

      if (data && data.length === 0) {
        setDevices([])
      } else if (data && data.length !== 0) {
        data.forEach((info) => {
          const soSplit = info?.so?.split('_-_') || []
          const so = soSplit.length > 1 ? `${soSplit[0]} ${soSplit[1]}` : soSplit[0] || ''
          info.so = so

          const modelSplit = info?.model?.split('_-_') || []
          const model = modelSplit.length > 1 ? `${modelSplit[0]} ${modelSplit[1]}` : modelSplit[0] || ''
          info.model = model

          if (info.last_date === null) {
            info.last_date = '10-01-2023 10:23:00'
          } else {
            info.last_date = formatDate(info.last_date)
          }
        })
        setDevices(data)
      }
      setTotalRecords(response?.data.count)
      setLoading(false)

      if (props) {
        setLazyParams({
          ...lazyParams,
          reload: false
        })
      }
    }
  }

  useEffect(() => {
    loadLazyData()
  }, [lazyParams])

  const isSelectable = (data) => data.is_selectable !== false

  const isRowSelectable = (event) =>
    event.data ? isSelectable(event.data) : true

  const rowClassName = (data) => (isSelectable(data) ? '' : 'p-disabled')

  const onPage = (event) => {
    setLazyParams({
      ...lazyParams,
      page: event.page,
      first: event.first
    })
  }

  const onSort = (event) => {
    setLazyParams({
      ...lazyParams,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    })
  }

  const onSelectionChange = (event) => {
    if (event.value) {
      if (table !== 'addDevices') onSelecteds(event.value)
      else onSelecteds(event.value, table)
    } else {
      if (table === 'addDevices') onSelecteds(event, table)
      else onSelecteds(event)
    }
  }

  const onGlobalFilter = (e) => {
    loadLazyData()
  }

  const onChangeFilterInput = (e) => {
    setGlobalFilterValue(e.target.value)

    if (e.target.value === '') {
      setTimeout(() => {
        loadLazyData()
      }, 1500)
    }
  }

  const onKeyUpInputText = (e) => {
    if (e.key === 'Enter') {
      loadLazyData()
    }
  }

  const onFilters = (e) => {
    setSelectedFilter(e.value)
    setLazyParams({
      ...lazyParams,
      filter: e.value
    })
  }

  const renderHeader = (columnsRole) => {
    const columnsPdfYCsv = columnsRole.filter((column) => column.field !== 'type' && column.field !== 'status_lock')
    const dropdownOptions = []
    columnsRole.forEach((colum) => {
      if (colum.field !== 'type' && colum.field !== 'status_lock' && colum.field !== 'last_date') {
        dropdownOptions.push({ label: colum.header, value: colum.field })
      }
    })
    return (
      <div className="containerHeader">
        {user.range === 3
          ? null
          : (
            <div className="containerButtons">
              <div className="buttonExtract">
                {exportsCsv && <Button
                  type="button"
                  icon="pi pi-file"
                  onClick={() =>
                    exportCSV(
                      table === 'reports' ? dateQuery : undefined, columnsPdfYCsv
                    )
                  }
                  className="mr-2"
                  data-pr-tooltip="Descargar CSV"
                  tooltip="Descargar CSV"
                  tooltipOptions={{ position: 'top' }}
                />}
                {exportsPdf && <Button
                  type="button"
                  icon="pi pi-file-pdf"
                  onClick={() =>
                    exportPdf(
                      table === 'reports' ? dateQuery : undefined, columnsPdfYCsv, 'devices'
                    )

                  }
                  className="p-button-success mr-2"
                  data-pr-tooltip="Descargar PDF"
                  tooltip="Descargar PDF"
                  tooltipOptions={{ position: 'top' }}
                />}
              </div>
              {table !== 'reports' && clearSelection &&
                <Button
                  type="button"
                  onClick={() => onSelectionChange([])}
                  className="p-button-success mr-2 uncheck"
                >
                  Limpiar selección
                </Button>}
            </div>
            )}
        {table !== 'reports'
          ? (
            <div
              className="searchContainer"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '0.5rem'
              }}
            >
              <span className="p-input-icon-left">
                <i className="pi pi-search search" onClick={onGlobalFilter} />
                <InputText
                  value={globalFilterValue}
                  onChange={onChangeFilterInput}
                  placeholder="Buscar..."
                  ref={it}
                  onKeyUp={onKeyUpInputText}
                />
              </span>
              <Dropdown
                value={selectedFilter}
                options={dropdownOptions}
                onChange={onFilters}
                optionLabel="label"
                optionValue="value"
                className="filterDropdown"
              />
            </div>
            )
          : null}
      </div>
    )
  }

  const tableHeader = renderHeader(columnsRole)

  const states = (rowData) => {
    const typeColumn = columnsRole.find((col) => col.field === 'type')
    const statusLockColumn = columnsRole.find((col) => col.field === 'status_lock')
    return (
      <States
        alert={rowData.stat}
        title={statusLockColumn !== undefined
          ? rowData.status_lock === true
            ? 'Bloqueado'
            : rowData.status_lock === false || rowData.status_lock === null
              ? 'Desbloqueado'
              : 'Desconocido'
          : 'Desconocido'
        }
        type={typeColumn !== undefined ? rowData.type : undefined}
      ></States>
    )
  }

  const deleted = (rowData) => {
    return (
      <ActionDelete
        id={rowData.id}
        table={table}
        reload={loadLazyData}
      ></ActionDelete>
    )
  }

  const dashboard = (rowData) => {
    return (
      <ActionDashboard
        type={table === 'devices' ? 'device' : 'group'}
        id={rowData.id}
        data={rowData}
      ></ActionDashboard>
    )
  }

  const dashboardDevice = (rowData) => {
    if (table === 'devices') {
      if (actionDashboard) {
        return (
          <NavLinkDashboardDevices
            id={rowData.id}
            data={rowData}
          ></NavLinkDashboardDevices>
        )
      } else {
        return (rowData.id_user)
      }
    } else {
      return (rowData.id_user)
    }
  }

  const rowsPerPageDropdown = () => {
    const dropdownOptions = [
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '20', value: 20 }
    ]

    const onRowsPerPageChange = (e) => {
      setSelectedRowsPerPage(e.value)
      setLazyParams({
        ...lazyParams,
        rows: e.value
      })
    }

    return (
      <div className="footerDataTable">
        <span
          className="mx-1"
          style={{ color: 'var(--text-color)', userSelect: 'none' }}
        >
          Dispositivos por página:
        </span>
        <Dropdown
          value={selectedRowsPerPage}
          options={dropdownOptions}
          onChange={onRowsPerPageChange}
          optionLabel="label"
          optionValue="value"
          className="rowsDropdown"
        />
        <p style={{ marginBottom: '0', fontWeight: 'normal' }}>
          {lazyParams.rows > devices.length
            ? `Mostrando ${devices.length} de ${totalRecords}`
            : `Mostrando ${lazyParams.first + 1} a ${lazyParams.first + selectedRowsPerPage
            } de ${totalRecords}`}
        </p>
      </div>
    )
  }

  if (devices === null) {
    return (
      <div>
        <Loading color="primary" />;
      </div>
    )
  } else {
    return (
      <div className="datatable-doc-demo">
        <DataTable
          size="normal"
          className="mt-4"
          ref={dt}
          value={devices}
          lazy
          dataKey="id"
          paginator
          onPage={onPage}
          onSort={onSort}
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          rows={lazyParams.rows}
          first={lazyParams.first}
          // responsiveLayout="scroll"
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No existen datos para mostrar."
          selection={selectedRows}
          onSelectionChange={onSelectionChange}
          header={tableHeader}
          footer={rowsPerPageDropdown}
          isDataSelectable={isRowSelectable}
          rowClassName={rowClassName}
          scrollable
        // showGridlines
        >
          {table !== 'reports' && rowSelecteds &&
            <Column
              selectionMode="multiple"
              headerClassName="selectionChexbox"
              frozen
              align="center"
              // alignHeader="center"
              headerStyle={{ minWidth: '80px' }}
              bodyStyle={{ minWidth: '80px' }}
            />
          }
          {table !== 'reports'
            ? (
              <Column
                header="Estado"
                align="center"
                // alignHeader="center"
                body={states}
                frozen
                headerStyle={{ minWidth: '110px', display: 'block', textAlign: 'center' }}
                bodyStyle={{ minWidth: '110px' }}
              />
              )
            : null}
          {columnIdUser.length > 0
            ? (
              <Column
                key={columnIdUser[0].field}
                body={dashboardDevice}
                header={columnIdUser[0].header}
                sortable
                frozen
                align="center"
                // alignHeader="center"
                headerStyle={{ minWidth: '250px', display: 'block', textAlign: 'center' }}
                bodyStyle={{ minWidth: '250px', padding: '0', wordBreak: 'break-all' }}
              />
              )
            : null}

          {columns.map((column) => {
            // console.log(column)
            return (<Column key={column.field} field={column.field} header={column.header} sortable align="center" /* alignHeader="center" */ headerStyle={{ minWidth: '200px', display: 'block', textAlign: 'center' }} bodyStyle={{ minWidth: '200px', wordBreak: 'break-all' }} />)
          })}

          {user.range === 1 && table !== 'reports' && table !== 'devices' && actionDashboard &&
            <Column
              headerStyle={{
                width: '4rem',
                textAlign: 'center'
              }}
              bodyStyle={{
                textAlign: 'center',
                overflow: 'visible'
              }}
              style={{ height: '5.5rem' }}
              body={dashboard}
            />}
          {user.range === 1 && table !== 'reports' && actionDelete &&
            <Column
              bodyStyle={{
                textAlign: 'center',
                overflow: 'visible'
              }}
              body={deleted}
            />}
        </DataTable>
      </div>
    )
  }
}

DataTableLazyDevices.propTypes = {
  table: PropTypes.string,
  selectedRows: PropTypes.array,
  dateQuery: PropTypes.string,
  onSelecteds: PropTypes.func,
  groupId: PropTypes.bool,
  exportsPdf: PropTypes.bool,
  exportsCsv: PropTypes.bool,
  rowSelecteds: PropTypes.bool,
  clearSelection: PropTypes.bool,
  actionDashboard: PropTypes.bool,
  columnsRole: PropTypes.array,
  actionDelete: PropTypes.bool
}

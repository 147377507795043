import React, { useState, useEffect, useRef } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { getData, putData, postData } from '../../../actions/servicesHttp'
import { Toast } from 'primereact/toast'
import Swal from 'sweetalert2'
import SendMessages from '../../../components/modals/sendMessage'
import Mapa from '../../../components/generals/map'
import Header from '../../../components/generals/header'
import ModalApps from '../../../components/modals/modalApps'
import BoxChart from '../../../components/generals/boxCharts'
import LockedDevices from '../../../components/modals/lockeds'
import ModalInforms from '../../../components/modals/modalInforms'
import ModalBackgroundChange from '../../../components/modals/modalBackgroundChange'
import ModalContentDownload from '../../../components/modals/modalContentDownload'
import ModalRSDevice from '../../../components/modals/modalsRxartSecure/modalRSDevice'
import ModalDebugLog from '../../../components/modals/modalsRxartSecure/modalDebugLog'
import ModalSendCommands from '../../../components/modals/modalsRxartSecure/modalSendCommands'
import ModalDeleteFolders from '../../../components/modals/modalsRxartSecure/modalDeleteFolders'
import { formatDate } from '../../../components/generals/charts/utils/DatesFormats'
import { ProgressSpinner } from 'primereact/progressspinner'
import SpeedDialMenu from '../../../components/Dashboards/speedDialMenu'
import infoCard from './JSON/info'
import formatConnections from './Assets/formatConnections'
import styles from '../../../css/Dashboards/DashDevices.module.css'
import stylesIndex from '../../../css/Index.module.css'
import CardsInfo from '../../../components/Dashboards/Devices/cardsInfo'
import CardsStats from '../../../components/Dashboards/Devices/cardsStats'
import store from '../../../reducer/store'
import UnlicensedMessage from '../../../components/generals/UnlicensedMessage'
import { ConnectionManager, Emmiter } from '../../../actions/SoketIoService/ConnectionManager'
import CardsStatsRealTime from '../../../components/Dashboards/Devices/cardsStatsRealTime'

export default function Dashboard () {
  const { id } = useParams()
  const { user } = store.getState()
  const role = user.roles

  // ? urls de la API
  const [deviceUrl] = useState('device/data')
  const [preferenceUrl] = useState('preferences')
  const [statUrl] = useState('device/stats')
  const [locationsUrl] = useState('device/last_location')
  const [connectionsUrl] = useState('device/connections')

  // //* estados de componentes
  const [device, setDevice] = useState()
  const [stat, setStat] = useState()
  const [locations, setLocations] = useState()
  const [connections, setConnections] = useState()
  const [preferences, setPreferences] = useState()
  const [licence, setLicence] = useState(null)

  const toast = useRef(null)
  const [action, setAction] = useState()
  const [labelConn, setLabelConn] = useState()
  const [flagReload, setFlagReload] = useState(false)

  // Estados para mostrar o no los modales
  const [modalsShow, setModalsShow] = useState({
    message: false,
    lockeds: false,
    reports: false,
    apps: false,
    backgroundChange: false,
    contentDownload: false,
    RSDevice: false,
    errorLogs: false,
    sendCommand: false,
    deleteFolders: false
  })

  //* Estados ws
  const sok = useRef(ConnectionManager()).current
  const [realTime, setRealTime] = useState(false)
  const [deviceConnect, setDeviceConnect] = useState(false)
  const [clientConnect, setClientConnect] = useState(false)

  //* se separaron la funcion socket para poder enviar dicha funcion cada componente
  const socketReceiveRam = (setRam) => {
    sok.on('statRam', (res) => {
      // console.log(res)
      setRam(res)
    })
  }
  const socketReceiveDisk = (setDisk) => {
    sok.on('statDisk', (res) => {
      // console.log(res)
      setDisk(res)
    })
  }
  const socketReceiveBattery = (setBattery) => {
    sok.on('statBattery', (res) => {
      // console.log(res)
      setBattery(res?.Battery)
    })
  }
  const socketReceiveLocations = (setLocation, setLabelRealTime) => {
    sok.on('locations', (res) => {
      setLocation(res)
      setLabelRealTime(true)
    })
  }
  const socketReceiveActionsCompleted = (setFlagReload) => {
    sok.on('actionsCompleted', (res) => {
      setFlagReload(true)
    })
  }
  const socketReceiveCommand = (handleReceiveAction) => {
    sok.on('command', (res) => {
      if (typeof handleReceiveAction === 'function') {
        handleReceiveAction(res)
      }
    })
  }
  const socketReceive = () => {
    sok.on('deviceConnect', (res) => {
      // console.log({ res })
      setDeviceConnect(res.device)
    })
    sok.on('clientConnect', (res) => {
      setClientConnect(res.client)
    })
  }

  const socketSendActions = (json) => {
    // console.log(json, 'socketSendActions', device?.licence)

    if (device?.licence) {
      Emmiter(
        sok,
        'actions',
        {
          identity: device?.licence,
          actions: json
        }
      )
    }
    // return json
  }

  //* Efectos ws
  useEffect(() => {
    socketReceive()
  }, [])

  useEffect(() => {
    if (device?.licence) {
      Emmiter(sok, 'openState', { identity: device?.licence, device: true })
      setDeviceConnect(true)
    }
  }, [device?.licence])

  useEffect(() => {
    if (deviceConnect && clientConnect && device?.licence) {
      setRealTime(true)
      Emmiter(sok, 'connectDevice', { identity: device?.licence, state: 'open' })
    } else {
      setRealTime(false)
    }
  }, [deviceConnect, clientConnect, device?.licence])

  //* Llamadas a la API
  useEffect(() => {
    getRequest(deviceUrl, id, setDevice, setLicence)
  }, [deviceUrl, id])
  useEffect(() => {
    if (licence) getRequest(preferenceUrl, 1, setPreferences)
  }, [preferenceUrl, id, licence])
  useEffect(() => {
    if (licence) getRequest(connectionsUrl, id, setConnections)
  }, [connectionsUrl, id, licence])
  useEffect(() => {
    if (licence) {
      getRequest(statUrl, id, setStat)
    }
  }, [statUrl, id, licence])
  useEffect(() => {
    if (licence) {
      getRequest(locationsUrl, id, setLocations)
    }
  }, [locationsUrl, id, licence])

  //* lanza alerta de alarma y hace el disable
  useEffect(() => {
    disabledAlarms(stat)
  }, [stat])

  //* efecto de label de connection
  useEffect(() => {
    if (connections) {
      const label = formatConnections(connections)
      setLabelConn(label)
    }
  }, [connections])

  useEffect(() => {
    getRequest(deviceUrl, id, setDevice, setLicence)
    if (flagReload) setFlagReload(false)
  }, [flagReload])

  // //* llamador de datos de API
  const getRequest = async (url, data, set, setLic) => {
    const response = await getData(url, data)
    // console.log(response, url)
    if (response?.status === 200) {
      set(response.data)
      if (url === 'device/data') {
        if (response?.data) {
          response.data.last_date = formatDate(response.data.last_date)
        }
        setLic(response.data.licence)
      }
    } else {
      set({ error: true, code: response?.code })
    }
  }

  //* funcion de desactivacion de alarmas por defecto
  async function disabledAlarms (stat) {
    if (stat) {
      if (stat.checkAlert) {
        const json = {
          id: stat.id,
          checkAlert: false
        }

        await putData('stats', json)

        toast.current.show({
          severity: 'error',
          summary: 'Alerta de uso excesivo!',
          sticky: true
        })
      }
    }
  }

  // ? Va seteando el estado para reenderizar los modales
  function handleModal (type, boolean) {
    if (type === 'bloquear' || type === 'desbloquear') {
      setAction(type)
      setModalsShow({ ...modalsShow, lockeds: boolean })
    } else {
      if (type === 'sendCommand') {
        boolean
          ? Emmiter(sok, 'connectDevice', { identity: device?.licence, state: 'open-cmd' })
          : Emmiter(sok, 'connectDevice', { identity: device?.licence, state: 'close-cmd' })
      }
      setModalsShow({ ...modalsShow, [type]: boolean })
    }
  }

  //* funcion de recarga de device
  const getDevice = async () => {
    await getData(deviceUrl, id, setDevice)
  }

  //* funcion de modales
  async function deleted (action, data) {
    const jsonAction = {
      touch: action === 'desactivar',
      screen: action === 'desactivar'
    }

    const json = {
      action: 'disable',
      data: {
        statusLock: action === 'desactivar',
        preferences: jsonAction
      },
      devicesId: [parseInt(id)],
      groupsId: []
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons
      .fire({
        title: `Esta seguro que desea ${action === 'activar' ? 'activar el dispositivo?' : 'desactivar momentáneamente el dispositivo?'
          }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        reverseButtons: true
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          if (action === 'desactivar') {
            device.motive_lock = 'disabled'
          } else if (action === 'activar') {
            device.motive_lock = 'activated'
          }

          if (realTime) {
            socketSendActions([json])
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Acción realizada con éxito!',
              text: `${action === 'activar' ? 'Activación' : 'Desactivación'
                } exitosa!`,
              showConfirmButton: false,
              timer: 2000
            })
          } else {
            const { data } = await postData('actions', [json])
            if (data[0]?.result) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Acción realizada con éxito!',
                text: `${action === 'activar' ? 'Activación' : 'Desactivación'
                  } exitosa!`,
                showConfirmButton: false,
                timer: 2000
              })
              // if (data[1]?.sendings.length > 0) setDevice()
            } else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Error. No se pudo realizar la acción!',
                text: `${action === 'activar' ? 'Activación' : 'Desactivación'
                  } fallida!`,
                showConfirmButton: false,
                timer: 2000
              })
            }
          }
          await putData('device', data)
          setFlagReload(true)
        }
      })
  }
  const infoJson = infoCard(role?.dashboardDevicePage)

  const validationToShowSpeedDialButton = (role, device) => {
    let show = false

    const dashboardDevice = Object.keys(role)

    dashboardDevice.forEach((key) => {
      if (key.includes('action') && key !== 'actionRxartSecure') {
        show = true
      } else if (key === 'actionRxartSecure' && device?.id_rxart) {
        show = true
      }
    })

    return show
  }

  return (
    <div className={`content-wrapper  ${stylesIndex.flexContent}`}>
      <div className={`content  ${stylesIndex.mainContent}`}>

        <div>
          {licence === null && <UnlicensedMessage />}
          {device && role?.dashboardDevicePage.buttonSpeedDial && validationToShowSpeedDialButton(role?.dashboardDevicePage, device) && licence !== null && (
            <SpeedDialMenu
              onHide={handleModal}
              data={device}
              deleted={deleted}
              type="devices"
              id={id}
              identity={device.identity}
              dashboardDevicePage={role?.dashboardDevicePage}
              socketSendActions={socketSendActions}
              realTime={realTime}
              setDevice={setDevice}
            />

          )}
          {device && (
            <div>
              <Toast ref={toast} position="top" />
              <Header
                title={device.name}
                realTime={realTime}
                edit={true}
                data={device}
                type="device"
                reload={getDevice}
              />
            </div>
          )}
          <section>
            {device && <CardsInfo
              device={device}
              infoCards={infoJson}
              role={role}
              socketReceiveActionsCompleted={socketReceiveActionsCompleted}
              realTime={true}
              setFlagReload={setFlagReload}
            />}
            {licence !== null && (!realTime) && <CardsStats
              stat={stat}
              preferences={preferences}
              connections={connections}
              labelConn={labelConn}
              role={role}
            />}
            {licence !== null && realTime && <CardsStatsRealTime
              funcSokDisk={socketReceiveDisk}
              funcSokRam={socketReceiveRam}
              funcSokBattery={socketReceiveBattery}
              preferences={preferences}
              connections={connections}
              labelConn={labelConn}
              stat={stat}
              role={role}
            />}
          </section>
          {role?.dashboardDevicePage.lastLocationMap && licence !== null && <section className={styles.principalSection} style={{ height: '40rem' }}>
            <div className={styles.bodyDash}>
              {role?.dashboardDevicePage.lastLocationMap && <BoxChart
                width="100%"
                title="Geoposicionamiento"
                icon="pi pi-map-marker"
                id={id}
                height="39rem"
              >
                {locations === undefined
                  ? (
                    <ProgressSpinner />
                    )
                  : locations?.error || locations.length === 0
                    ? (
                      <h5>Sin data a mostrar</h5>
                      )
                    : (
                        locations && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: 'auto',
                            alignItems: 'center',
                            position: 'relative'
                          }}
                        >
                          {role?.dashboardDevicePage.moreLocationsButton && <NavLink
                            to={`/mdm/history/${id}`}
                            className={`btn btn-dark ${styles.navLinkLastLocation}`}
                            type="button"
                            onClick={() => {
                              window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                              })
                            }}
                          >
                            <i
                              className="pi pi-map-marker"
                              style={{ marginRight: '0.5rem' }}
                            ></i>
                            Ubicaciones
                          </NavLink>}
                          <Mapa
                            realTime={realTime}
                            info={locations?.last_location}
                            funcSokLocation={socketReceiveLocations}
                            type="last"
                          />
                        </div>
                        )
                      )}
              </BoxChart>}
            </div>
          </section>}
          {device && (
            <>
              {/* MODAL APLICACIONES */}
              <ModalApps
                show={modalsShow.apps}
                onHide={() => handleModal('apps', false)}
                type="devices"
                so={device.so}
                identity={device.identity}
                dashboardDevicePage={role?.dashboardDevicePage}
                socketSendActions={socketSendActions}
                realTime={realTime}
              />

              {/* MODAL INFORMES */}
              <ModalInforms
                show={modalsShow.reports}
                onHide={() => handleModal('reports', false)}
              />

              {/* MODAL BLOQUEAR */}
              {preferences && (
                <LockedDevices
                  device={device}
                  so={device.so}
                  preferences={preferences.defaultThief}
                  configDefault={preferences.defaultThief.default}
                  action={action}
                  show={modalsShow.lockeds}
                  onHide={() => handleModal('lockeds', false)}
                  devices={[parseInt(id)]}
                  reload={getDevice}
                  title={`¿Está seguro que quiere ${action} el dispositivo?`}
                  identity={device.identity}
                  socketSendActions={socketSendActions}
                  realTime={realTime}
                  setFlagReload={setFlagReload}
                />
              )}

              {/* MODAL ENVIAR MENSAJE */}
              <SendMessages
                title="Enviar Mensaje"
                show={modalsShow.message}
                onHide={() => handleModal('message', false)}
                devices={[parseInt(id)]}
                identity={device.licence}
                component="dashboardDevices"
                socketSendActions={socketSendActions}
                realTime={realTime}
              />

              {/* MODAL CAMBIO DE FONDO DE PANTALLA */}
              <ModalBackgroundChange
                show={modalsShow.backgroundChange}
                onHide={() => handleModal('backgroundChange', false)}
                title="Cambiar Fondo de Pantalla"
                btnError="Cerrar"
                btnSuccess="Enviar"
                type="devices"
                identity={device.identity}
                socketSendActions={socketSendActions}
                realTime={realTime}
              />

              {/* MODAL DE DESCARGA DE CONTENIDO */}
              <ModalContentDownload
                show={modalsShow.contentDownload}
                onHide={() => handleModal('contentDownload', false)}
                title="Descargar Contenido"
                btnError="Cerrar"
                btnSuccess="Enviar"
                type="devices"
                identity={device.identity}
                socketSendActions={socketSendActions}
                realTime={realTime}
              />

              {/* MODAL DE RXART SECURE DE UN DISPOSITIVO */}
              <ModalRSDevice
                data={device}
                show={modalsShow.RSDevice}
                onHide={() => handleModal('RSDevice', false)}
                title="Edición de Dispositivo"
                btnError="Cerrar"
                btnSuccess="Guardar"
              />

              {/* MODAL DE LOG DE ERRORES */}
              <ModalDebugLog
                show={modalsShow.errorLogs}
                onHide={() => handleModal('errorLogs', false)}
                devices={[parseInt(id)]}
                identity={device.identity}
                component="errorLogs"
              />

              {/* MODAL DE ENVIO DE COMANDOS */}
              <ModalSendCommands
                title="Ejecutar comandos en el equipo"
                show={modalsShow.sendCommand}
                onHide={() => handleModal('sendCommand', false)}
                devices={[parseInt(id)]}
                identity={device.identity}
                component="sendCommand"
                socketSendActions={socketSendActions}
                socketReceiveActions={socketReceiveCommand}
                realTime={realTime}
              />

              {/* MODAL DE BORRADO DE CARPETAS */}
              <ModalDeleteFolders
                title="Borrado de Carpetas"
                show={modalsShow.deleteFolders}
                onHide={() => handleModal('deleteFolders', false)}
                btnClose="Cerrar"
                btnSuccess="Eliminar"
                devices={[parseInt(id)]}
                identity={device.identity}
                component='deleteFolders'
                socketSendActions={socketSendActions}
                realTime={realTime}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}
